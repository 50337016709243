import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./Homepage";
import Index2 from "./Index2";
import Aboutpage from "./Aboutpage";
import ServicePage from "./Servicepage";
import Contactpage from "./Contactpage";
import AuthForm from "./signin";
import Profile from "./Profile";
import Loader from "./Components/Loader";
import SEO from "./SEO";
import firebase from 'firebase/compat/app';
import Demo from "./Components/demo";
import AnimLoader from "./Components/animloader";
import TestimonialPage from "./TestimonialPage";
import ResumeUploader from "./resumeUploader";
import AdminPage from "./AdminPage";
import BlogEdit from "./BlogEdit";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import CaseStudies from "./CaseStudies";
import CSudiesPage from "./CSudiesPage";
import TeamPage from "./TeamPage";
import CaseStudiesDetails from "./CaseStudiesDetails";

const App = () => {
  const [metaTitle, setMetaTitle] = useState('');
  const [keywords, setKeywords] = useState('');
  const [primaryKeywords, setPrimaryKeywords] = useState('');
  const [secondaryKeywords, setSecondaryKeywords] = useState('');

  
useEffect(() => {
  const metaDataRef = firebase.database().ref('metadata');

  // Fetch metadata from the database
  metaDataRef.once('value').then((snapshot) => {
      const metaData = snapshot.val();
      if (metaData) {
          setMetaTitle(metaData.metaTitle || '');
          setKeywords(metaData.keywords || '');
          setPrimaryKeywords(metaData.primaryKeywords || '');
          setSecondaryKeywords(metaData.secondaryKeywords || '');

      }
  }).catch((error) => {
      console.error('Error fetching metadata:', error);
  });
}, []);

  useEffect(() => {


    
    const keywordsMeta = document.querySelector('meta[name="keywords"]');
    const primaryKeywordsMeta = document.querySelector('meta[name="primary keywords"]');
    const secondaryKeywordsMeta = document.querySelector('meta[name="secondary keywords"]');

    console.log('Keywords meta content:', keywordsMeta ? keywordsMeta.content : null);
    console.log('Primary keywords meta content:', primaryKeywordsMeta ? primaryKeywordsMeta.content : null);
    console.log('Secondary keywords meta content:', secondaryKeywordsMeta ? secondaryKeywordsMeta.content : null);

    if (keywordsMeta) {
        keywordsMeta.setAttribute('content', keywords);
    }

    if (primaryKeywordsMeta) {
        primaryKeywordsMeta.setAttribute('content', primaryKeywords);
    }

    if (secondaryKeywordsMeta) {
        secondaryKeywordsMeta.setAttribute('content', secondaryKeywords);
    }
}, [metaTitle,keywords, primaryKeywords, secondaryKeywords]);


  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true); // State to track loader visibility

  useEffect(() => {
    // Simulate content loading time (e.g., for demonstration purposes)
    setTimeout(() => {
      setLoading(false); // Hide the loader after some time
    }, 4000);

    // Check if user is already logged in
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
    return unsubscribe;
  }, []);

  return (
    <>
   
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/index" element={<Index2 />} />
            <Route path="/about" element={<Aboutpage />} />
            <Route path="/service/:id" element={<ServicePage />} />
            <Route path="/contact" element={<Contactpage />} />
            <Route path="/login" element={<AuthForm />} />
            <Route path="/demo" element={<Demo />} />
            <Route path="/AnimLoader" element={<AnimLoader />} />
            <Route path="/testimonial" element={<TestimonialPage />} />
            <Route path="/blog" element={<Blog/>}/>
            <Route path="/team" element={<TeamPage/>}/>
            <Route path="/casestudies" element={<CSudiesPage/>}/>
            <Route path="/blog/blog-details/:title" element={<BlogDetails />} />
            <Route path="/casestudies/casestudies-details/:title" element={<CaseStudiesDetails/>}/>
           
            {isLoggedIn && (
              <>
                <Route path="/profile" element={<Profile />} />
                <Route path="/seo" element={<SEO />} />
                <Route path="/resume-uploader" element={<ResumeUploader/>} />
                <Route path="/adminpage" element={<AdminPage/>} />
                <Route path="/blog-edit" element={<BlogEdit/>}/>
                <Route path="/casestudies-edit" element={<CaseStudies/>}/>
              </>
            )}
          </Routes>
        </BrowserRouter>
      
    </>
  );
};

export default App;
