import React,{useState, useEffect} from "react";
import Navbar from "./Components/Navbar";
import About from "./Components/about";
import { applyColorMode } from "./ColorMode";
import Footer from "./Components/Footer";
import AnimLoader from "./Components/animloader.js";
import Team1 from "./Components/Team1.js";

const TeamPage=()=>{
    useEffect(()=>{
            document.title="Team | Cogniglob"
            applyColorMode();
          },[]);
    return(
        <div>
            <Navbar/>
                <AnimLoader/>
                <Team1/>
            <Footer/>
        </div>
    );
}

export default TeamPage;