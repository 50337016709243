import React, { useEffect, useState } from 'react';
import "./Loader.css";
import IconImage from "../assets/img/Cogniglob2.png";

const Loader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to hide the loader after 1.5 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => clearTimeout(timer); // Cleanup the timer when the component unmounts
  }, []);

  if (loading) {
    return (
      <div id="preloader-active">
        <div className="preloader d-flex align-items-center justify-content-center">
          <div className="preloader-inner position-relative">
            <div className="preloader-circle" />
            <div className="preloader-img pere-text">
              <img src={IconImage} alt="Cogniglob" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  
  return null;
};

export default Loader;
